<template>
    <Header/>
    <PPCheader/>
    <PPCservice/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import PPCservice from '@/components/PPCservice.vue'
import PPCheader from '@/components/PPCheader.vue'

export default {
  components: {
     Header,
    Footer,
    PPCservice,
    PPCheader
  }
}
</script>