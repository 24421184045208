<template>
    <section id="PPCService" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we Do</h2>
          <p style="padding-top:20px">We oversee the marketing company’s PPC strategy and budget. We monitor closely the traffic, leads generated and the conversions made through Google Analytics account. </p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="font-size: 15px;line-height: 25px;">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Keyword-Research.jpg',
        header: 'Keyword Research',
        caption: 'For any form of marketing in any platform, it’s necessary that keyword research is done before deciding the final content. The search engine filters the most relevant website on top of Google search index. To match the criteria of landing on top of Google search index, a series of steps are undertaken.'
      },
      {
        path: 'services/Targeting-Goals.jpg',
        header: 'Targeting Goals in Campaigns',
        caption: 'After appearing on the Google search index page, the landing page experience and the conversions made on the page are goal- oriented. Customer relations and knowledge of the customer wants is being a step ahead in achieving goals in developed campaigns. To continually achieve goals, constant managing and monitoring of PPC is necessary.'
      },
      {
        path: 'services/PPC-Monitoring.jpg',
        header: 'PPC Monitoring',
        caption: 'Before monitoring PPC of a business, the key business metrics such as ROI, ROAS, which is about the profit and the overall ad budget of the company, is researched. The kind of target audience is sought out, each keyword is measured by testing to see if it would yield more PPC is studied and it the words are updated generally. '
      },
      {
        path: 'services/Continuous-optimization.jpg',
        header: 'Continuous optimization and results',
        caption: 'A campaign is generated to boost sales for a company. By continuous update, optimization and constant search for the new ways to develop new goals, reach different kinds of audiences. This kind of optimization would generate valid results. There are also several tools that help analyze the content.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#PPCService {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>